.root {
    padding-top: 60px;
}

.cardBody {
    padding-bottom: 20px;
    width: 80%;
    margin: auto;
}


.innerCardBody {
    width: 75%;
    margin-bottom: 0;
    margin-top: 0;
    margin: auto;
    padding-bottom: 32px;
}

.innerCardBody p {
    margin: 0
}

.avatarLogo {
    margin: auto;
    width: 8rem;
    /* height: 8rem !important; */
    margin-right: 2rem;
}
.jobContent {
    font-size: 0.9rem;
    margin: 0;
    text-align: justify;
}

.jobContent {
    font-size: 0.9rem;
    margin: 0;
    text-align: justify;
}

.awardContent {
    font-size: 0.9rem;
    margin: 0;
    text-align: justify;
}


.avatarMobileLogo {
    width: 0px;
    height: 0px;
    display: none;
}

.centerOnMobile {
    text-align: left;
    margin: 0;
}

.skill {
    padding: 8px;
    font-weight: 600
}

.noGreenLink:any-link {
    color: white;
    text-decoration: underline;
}


@media only screen and (max-width: 800px) {
    .cardBody {
        width: 90%;
    }
    .innerCardBody {
        width: 90%;
    }
    
    .avatarLogo {
        width: 0px;
        height: 0px;
        margin-right: 0;
        display: none;
    }
    .jobContent {
        text-align: justify;
        font-size: 0.75rem;
    }
    .avatarContent h4 {
        text-align: center;
    }
    .avatarContent {
        margin-left: 0;
    }
    .avatarMobileLogo {
        width: 6rem;
        height: 100%;
        margin-bottom: 1.3rem;
        display: block;
    }    
    .centerOnMobile {
        text-align: center;
    }
    .skill {
        font-size: 1rem;
        margin: 4px;
    }
}