.root {
    background-color: black;
    min-height: 100vh;
    margin-top: 60px;
    padding-bottom: 60px;
}

.backgroundImg {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.heroRoot {
    height: 50vh;
    width: 100%;
    position: relative;
    text-align: center;
}

.heroInnerContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.heroInnerContent h4 {
    font-size: 48px;
    margin-bottom: 6px;
    margin-top: 6px;
    text-shadow: 2px 2px 5px black;
}

.heroInnerContent small {
    font-size: 18px;
    font-weight: 400;
    text-shadow: 1px 1px 3px black;
}

.profilePic {
    left: 0;
    object-fit: cover;
    height: 10vw !important;
    width: 10vw !important;
}

.announcement {
    font-weight: bold;
    font-size: 24px;
    padding: 48px;
    margin: 0 auto;
    text-align: justify;
}

.announcementDark {
    background-color: var(--ifm-color-gray-900);
    color: #fff;
}

.announcementInner {
    margin: 0 auto;
    font-size: 1rem;
    max-width: 50vw;
    padding-top: 48px;
    padding-bottom: 32px;
}

@media only screen and (max-width: 1000px) {
    .announcementInner {
        max-width: 85vw;
    }
    .heroInnerContent h4 {
        font-size: 20px;
        margin-bottom: 6px;
        margin-top: 6px;
        text-shadow: 2px 2px 5px black;
    }
    
    .heroInnerContent small {
        font-size: 0.8rem;
        font-weight: 400;
        text-shadow: 1px 1px 3px black;
    }
    
    .profilePic {
        left: 0;
        object-fit: cover;
        width: 20vw !important;
        height: 20vw !important;
    }
}